exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-page-about-js": () => import("./../../../src/templates/page-about.js" /* webpackChunkName: "component---src-templates-page-about-js" */),
  "component---src-templates-page-blog-js": () => import("./../../../src/templates/page-blog.js" /* webpackChunkName: "component---src-templates-page-blog-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-page-offer-js": () => import("./../../../src/templates/page-offer.js" /* webpackChunkName: "component---src-templates-page-offer-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

